import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import HostingPlanBlock from "../components/HostingPlans/HostingPlanBlock"
import InfoBlocks from "../components/Index/InfoBlocks"
import ClientFeedback from "../components/Index/ClientFeedback"
import Footer from "../components/App/Footer"
import BannerArea from "../components/Common/BannerArea"
import SEO from "../components/App/SEO"
import PromoInfoRowParams from "../components/PromoInfoRow/PromoInfoRowParams"
import SiteBenefitsParams from "../components/SiteBenefits/SiteBenefitsParams"
import CustomerSupportParams from "../components/Index/CustomerSupportParams"
import IntroHostingPlansParamsReseller from "../components/HostingPlans/IntroHostingPlansParamsReseller"
import IntroHostingBgWhite from "../components/HostingPlans/IntroHostingBgWhite";

const GazduireWebSsd = () => {
    return (
        <Layout>
            <SEO title="Choose to be a hosting reseller partner at Sitebunker.net - Reseller hosting"
                 description="As a hosting reseller at SiteBunker you enjoy multiple benefits and performant security. Check here reseller hosting subscriptions that suit your needs."
            />
            <Navbar/>
            <IntroHostingBgWhite
                title={'Become a Sitebunker Reseller and get dedicated resources for your customers'}
                subtitle={'See which package is right for you and enjoy the benefit of managing the resources allocated to your cPanel accounts through CloudLinux LVE Manager.'}
            />

            <HostingPlanBlock category="Reseller EN"
                              showPromos={false}/>
            <BannerArea
                pageTitle="Hosting Reseller"
                pageSubtitle="Become a SiteBunker hosting reseller and enjoy the benefits of a successful partnership."
                pageDescription="Premium resources, intuitive cPanel / WHM, efficient upload speed, stability and security, all provided at predictable costs. Join SiteBunker now as a hosting reseller and enjoy all the benefits."
                svgIllustration="reseller"
            />
            <PromoInfoRowParams
                title1="Premium services for every hosting reseller"
                title2="Easy to administrate/manage and allocate reseller hosting resources"
                title3="Free migration for your hosting reseller account"
                paragraph1="If you choose to partner with SiteBunker and become a hosting reseller you will benefit from a wide range of complete hosting services with premium resources included."
                paragraph2="Easily create cPanel packages and quickly allocate the desired resources for each package. You have 100% control over storage space, CPU, RAM, EP, NPROC, PMEM, IO and IOPS."
                paragraph3="At SiteBunker we transfer your Hosting Reseller account for FREE without any hassle. All you have to do is send us the WHM access provided by your old hosting provider and we will fully handle the migration."/>
            <InfoBlocks/>
            <ClientFeedback
                title="Curious why our clients chose "
                bold="SiteBunker?"/>
            <SiteBenefitsParams
                mainTitle="RESELLER HOSTING WITH PREMIUM RESOURCES INCLUDED"
                title1="DAILY BACKUP"
                title2="PERFORMANT SECURITY"
                title3="24/7 SUPPORT"
                text1="Rest assured, your data is safe with us. At SiteBunker we automatically perform FREE daily backups in external locations."
                text2="We guarantee advanced Imunify360 anti-hacking protection and increased security for DDoS attacks, provided by the newly implemented Arbor protection."
                text3="Our Customer Support Team is available 24/7. Contact us in just a few clicks and we will respond in a very short time to your ticket."/>
            <CustomerSupportParams
                title="NOW available at SiteBunker - VPS hosting servers"
                paragraph1="At SiteBunker you have VPS servers available, powered by AMD EPYC processors with NVMe full-SSD storage in RAID 10."
                buttonText="Ask details here"
                differentLink="/vps-servers"/>
            {/*<Partners/>*/}
            <Footer/>
        </Layout>
    )
}

export default GazduireWebSsd
