import React, { Component } from "react"

class IntroHostingPlansParamsReseller extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {title, subtitle} = this.props
    return (
      <section id="packages-intro">
        <div className="packages-area hosting-plans-intro container-fluid pt-100">
          <div className="container">
            <div className="section-title section-title-800">
              <h2 className="text-white h3-title">{title} </h2>
              <p className="text-white">{subtitle}</p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default IntroHostingPlansParamsReseller
